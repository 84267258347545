import React, { useEffect } from 'react'
import Seo from './../components/seo/seo'
import Layout from './../components/layout/Layout'
import { Link } from 'gatsby'
import { toast } from 'react-toastify'

const Contact = () => {
  const notify = (text, background) =>
    toast(text, {
      position: 'bottom-center',
      autoClose: 7000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined, // Prevents dismissing of toast on hover
      style: {
        background,
        color: '#FFF',
      },
    })
  useEffect(() => {
    setTimeout(() => {
      window.$(function () {
        window.$('input').change(function () {
          if (
            window.$('#exampleCheck1').is(':checked') &&
            window.$('#name').val() != '' &&
            window.$('#email').val() != ''
          ) {
            window.$('#contacts-form-submit-btn').removeAttr('disabled')
          } else {
            window.$('#contacts-form-submit-btn').attr('disabled', 'disabled')
          }
        })
      })

      window.$("input[type='file']").on('change', function () {
        if (this.files && this.files[0].size > 1068157) {
          notify(
            'Please upload file less than 1MB. Thanks!!',
            'linear-gradient(to right, #D7816A, #BD4F6C)'
          )
          window.$('#job-open-form-submit-btn').attr('disabled', 'disabled')
          window.$('#fileUpload').val('')
          const btnTextChange = document.querySelector('#fileTextChange')
          btnTextChange.innerHTML = `<i class="fi-rr-cloud-upload me-2 ms-0"></i> Upload a file`
        }
      })

      // call email api
      window.$(document).ready(function () {
        window.$('#contactForm').on('submit', function (e) {
          e.preventDefault()
          window.$('#contacts-form-submit-btn').text('Please wait..')
          window.$('#contacts-form-submit-btn').prop('disabled', true)

          var formdata = new FormData(this)

          window.$.ajax({
            url: 'https://mail.immence.com/email/contact-us',
            type: 'POST',
            cache: false,
            data: formdata,
            contentType: false,
            processData: false,
            success: function (response) {
              notify(
                'Thank you for contacting us. We will get back to you soon.',
                'linear-gradient(to right, #63A4FF, #0BAB64)'
              )
              window.$('#contacts-form-submit-btn').text('Send Message')
              window.$('#contacts-form-submit-btn').prop('disabled', true)
              window.$('#contactForm').trigger('reset')
              window.$('#fileUpload').val('')
              const btnTextChange = document.querySelector('#fileTextChange')
              btnTextChange.innerHTML = `<i class="fi-rr-cloud-upload me-2 ms-0"></i> Upload a file`
            },
            error: function (response) {
              notify(
                'Something went wrong. Please try again later.',
                'linear-gradient(to right, #D7816A, #BD4F6C)'
              )
              window.$('#contacts-form-submit-btn').text('Send Message')
              window.$('#contacts-form-submit-btn').prop('disabled', false)
            },
          })
        })
      })
    }, 700)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      var rellax = new window.Rellax('.rellax', {
        breakpoints: [576, 768, 1201],
      })

      window.AOS.init({
        duration: 1200,
        easing: 'ease-out-back',
      })
    }, 700)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const fileUpload = document.querySelector('#fileUpload')
      const btnTextChange = document.querySelector('#fileTextChange')

      fileUpload.addEventListener('change', (e) => {
        if (fileUpload.files?.[0]) {
          btnTextChange.innerHTML = `${fileUpload?.files?.[0]?.name}`
        }
      })
      if (window.Dropzone) {
        window.Dropzone.myGreatDropzone = {
          paramName: 'file', // The name that will be used to transfer the file
          maxFilesize: 2, // MB
          dictDefaultMessage:
            '<strong>Drop files here or click to upload. </strong></br> (This is just a demo dropzone. Selected files are not actually uploaded.)',
        }
      }
    }, 700)
  }, [])

  return (
    <>
      <Seo
        title={'Contact | immence'}
        description={
          'Leave your contacts below and we’ll get back to you within 24 hours.'
        }
      />
      <Layout>
        <section className="com-section contact-page content-page">
          <div className="container">
            <div className="row rowpadd">
              <div className="col-lg-5 col-md-5" data-aos="fade-up">
                <h1 className="let-talk">Let's talk</h1>
                <p className="mb-2">
                  Whether you have a big idea or an existing piece of technology
                  that’s in need of some TLC, we’d love to connect.
                </p>
                <div className="row">
                  <a href="mailto:contact@immence.com">
                    <div className="row center-item">
                      <div className="col-1 center-item">
                        <i className="fas fa-envelope" />
                      </div>
                      <div
                        className="col-11"
                        style={{ textAlign: 'left', padding: 0 }}
                      >
                        contact@immence.com
                      </div>
                    </div>
                  </a>
                </div>
                <div className="row">
                  <a href="tel:+918200237575" className="mt-2">
                    <div className="row center-item">
                      <div className="col-1 center-item">
                        <i className="fas fa-phone-alt" />
                      </div>
                      <div
                        className="col-11"
                        style={{ textAlign: 'left', padding: 0 }}
                      >
                        +91 8200237575 (HR)
                      </div>
                    </div>
                  </a>
                </div>

                <div className="contact-content-box">
                <a href="javascrtipt:void(0)"
                    target="_self"
                    rel="noreferrer"
                    className="text-center"
                  >
                    <div className="row center-item">
                      <div className="col-1 center-item">
                        <i className="fas fa-map-marked-alt" />
                      </div>
                      <div
                        className="col-11"
                        style={{ textAlign: 'left', padding: 0 }}
                      >
                        US Office
                      </div>
                    </div>
                  </a>

                  <p className="mb-2">
                    307 Walnut Drive, Venetia<br />
                    Pa 15367
                  </p>
                  
                </div>

                <div className="contact-content-box">
                <a href="javascrtipt:void(0)"
                    target="_self"
                    rel="noreferrer"
                    className="text-center"
                  >
                    <div className="row center-item">
                      <div className="col-1 center-item">
                        <i className="fas fa-map-marked-alt" />
                      </div>
                      <div
                        className="col-11"
                        style={{ textAlign: 'left', padding: 0 }}
                      >
                        India Office 
                      </div>
                    </div>
                  </a>

                  <p className="mb-2">
                    214, Baroda Commerce House, <br />
                    Beside Bansal Mall, Near Nilamber Circle, <br />
                    Gotri - Bhayli Road, Vasna, <br />
                    Vadodara, GJ - 390021
                  </p>
                  
                </div>
              </div>
              <div className="col-lg-7 col-md-7">
                <div className="contact-form-box" data-aos="fade-up">
                  <form id="contactForm">
                    <div className="mb-4 form-group">
                      <label htmlFor="name" className="form-label required">
                        Full name
                      </label>
                      <input
                        name="name"
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Type your name"
                        required
                      />
                    </div>
                    <div className="mb-4 form-group">
                      <label htmlFor="email" className="form-label required">
                        Email address
                      </label>
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Type your email"
                        required
                      />
                    </div>
                    <div className="mb-4 form-group">
                      <label htmlFor="commentBox" className="form-label">
                        Tell us about your project{' '}
                      </label>
                      <textarea
                        name="message"
                        id="commentBox"
                        className="form-control"
                        style={{ height: 150 }}
                        placeholder="Tell us about your project"
                        required
                        defaultValue={''}
                      />
                    </div>
                    <div className="mb-5 form-group">
                      <label htmlFor="file" className="form-label d-block">
                        Upload anything you want to share with us
                      </label>
                      <div className="upload-btn-wrapper">
                        <div className="dropzone dropdown-setting">
                          <div className="upload-btn-wrapper estimate-wrapper mb-4">
                            <div className="d-inline position-relative">
                              <input
                                className="form-control"
                                id="fileUpload"
                                name="file"
                                type="file"
                              />
                              <button
                                className="btn btn-outline-light btn-ouline-sm"
                                id="fileTextChange"
                              >
                                <i className="fi-rr-cloud-upload me-2 ms-0" />{' '}
                                Upload a file
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div class="d-inline-block">
              <p class="ms-2 mb-0"><small>Max file size 10MB.</small></p>
            </div> */}
                    </div>
                    <div className="mb-5 form-check form-group">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                      />
                      <label
                        className="form-check-label text-white"
                        htmlFor="exampleCheck1"
                      >
                        I hereby accept{' '}
                        <Link
                          to="/terms_of_use"
                          target="_blank"
                          rel="noreferrer"
                          className="text-white text-underline"
                        >
                          Terms of Use
                        </Link>{' '}
                        and{' '}
                        <Link
                          to="/privacy-policy"
                          target="_blank"
                          rel="noreferrer"
                          className="text-white text-underline"
                        >
                          Privacy Policy
                        </Link>
                      </label>
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-outline-light-fill btn-ouline"
                        id="contacts-form-submit-btn"
                        disabled="disabled"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Contact
